import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { PFTransferCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const CreditCardTransferPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, personalFinancePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('CreditCardTransferPage.subTitle', {
        defaultValue: 'Should I transfer my credit card balances?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('CreditCardTransferPage.textContent', {
                  defaultValue:
                    "When you carry high-interest credit card balances, it's worth exploring the option of transferring your balances to a card with a lower rate. This calculator can help you understand how much you might save.",
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: personalFinancePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('CreditCardTransferPage.pageTitle', {
              defaultValue: 'Balance Transfer Calculator',
            })}
            pathname="/calculators/personal-finance"
          />
          <CalculatorSectionModule
            pageTitle={t('CreditCardTransferPage.title', {
              defaultValue: 'Personal Finance Calculators',
            })}
            sections={pageData}
            calculator={<PFTransferCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

CreditCardTransferPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { CreditCardTransferPage }
export default CreditCardTransferPage
